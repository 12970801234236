import moment from "moment-timezone"
import enums from "~config/constants/enums"

const countChars = (string: any, char: any) => {
    return string.length - string.replaceAll(char, '').length
}
const stringify = (
    obj: object
) => {

    return JSON.stringify(obj)
        .replace('[', '')
        .replace(']', '')
}
const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) {
        return text;
    } else {
        return text.substring(0, maxLength - 3) + '...';
    }
}
const getLastFiveDigitsFromObjectId = (objectId: string) => {
    if (typeof objectId !== 'string' || objectId.length !== 24) {
        console.error("error~~~> wrong obj Id")
    }

    return objectId?.substring?.(19);
}
const getSubstrings = (
    char1: string,
    char2: string,
    string: string
) => {

    const regex
        = new RegExp(`\\${char1}(.*?)\\${char2}`, 'g');
    const paths: string[] = [];
    let match;

    while ((match = regex.exec(string)) !== null) {
        paths.push(match[1]);
    }

    return paths
}
const fetchProp = (
    mainValue: string,
    data: any
) => {
    try {

        let valueToReturn: any
            = mainValue

        const paths = getSubstrings(
            '[',
            ']',
            mainValue
        )

        paths?.map?.(path => {

            const keys = path?.split?.('.')

            let value = data

            keys?.map?.(key => {
                if (key.includes('where')) {

                    const comparisionPath
                        = getSubstrings(
                            '(',
                            ')',
                            key
                        )[0]
                    let [
                        comparisionKeys,
                        comparisionValue,
                        pathKeys,
                    ]: any = comparisionPath?.split?.('*')

                    comparisionKeys
                        = comparisionKeys?.split?.('>')

                    let matches
                        = value?.filter?.((obj: any) => {

                            let foundValue = obj

                            comparisionKeys.map((
                                comparisionKey: any
                            ) =>
                                foundValue
                                = foundValue[comparisionKey]
                            )

                            return foundValue == comparisionValue
                        })

                    pathKeys = pathKeys.split('>')

                    let commaSeparated = ""

                    matches?.map?.((match: any) => {

                        let localMatch = match

                        pathKeys?.map?.((pathKey: any) => {

                            localMatch = localMatch[pathKey]
                        })

                        commaSeparated
                            = localMatch + ', ' + commaSeparated
                    })

                    valueToReturn = commaSeparated

                }
                else if (key.includes('format')) {
                    const format
                        = getSubstrings(
                            '(',
                            ')',
                            key
                        )[0]
                    value = moment(value).tz('America/Chicago').format(format)
                }
                else if (key.includes('enum')) {
                    const enumType
                        = getSubstrings(
                            '(',
                            ')',
                            key
                        )[0]
                    value = enums[enumType][value]
                }
                else
                    value = value?.[key]
            })

            valueToReturn
                = valueToReturn
                    .replace(`[${path}]`, value)
        })

        if (valueToReturn.includes('==')) {

            const [
                leftHandSide,
                rest1
            ] = valueToReturn.split('==')

            const [
                rightHandSide,
                rest2
            ] = rest1.split('?')

            const [
                ifResult,
                elseResult
            ] = rest2.split(':')

            if (leftHandSide == rightHandSide)
                valueToReturn = ifResult
            else
                valueToReturn = elseResult

        }

        valueToReturn
            = valueToReturn
                .replace(`undefined`, '')

        if (valueToReturn?.includes?.('||')) {

            let [value1, value2]
                = valueToReturn.split('||')
            value1 = value1.trim()
            value2 = value2.trim()

            valueToReturn = value1 || value2
        }

        if (valueToReturn === "")
            return "--"
        return valueToReturn

    } catch (error) {

        console.error("error~~~>", error)

        return "--"
    }
}
const getId = () => {
    return Math.random().toString(16).slice(2)
}
const formatTime = (
    time: number
) => {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
        .toString()
        .padStart(2, '0')
    return `${minutes}:${seconds}`
}
const getName = (id: any) => {
    switch (id) {
        case 0:
            return "Playback";
        case 1:
            return "AdditionalVerse";
        case 2:
            return "ArtistTip";
        case 3:
            return "AudioGreeting";
        case 4:
            return "LyricsSheet";
        default:
            return "Unkown";
    }
}
const replaceVariables = (
    text: string,
    variables: string[]
) => {

    let dynamicText
        = text

    variables.map((
        item,
        key
    ) => {

        dynamicText = dynamicText.replace(
            `[${key}]`,
            item
        )
    })

    return dynamicText
}
const getExtension = (
    string: string
) => {

    const parts
        = string.split('.')

    return '.' + parts[parts.length - 1]
}
const downloadURI = (
    uri: string,
    name: string
) => {
    var link = document.createElement("a");
    link.target = "_blank";
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
const getUsername = (
    stageName: string,
    firstName: string,
    lastName: string,
) => {
    return (
        stageName
        ||
        (firstName &&
            firstName + ' ' + lastName
        )
    )
}


const parseCloudinaryParamsString: (queryString: string) => Record<string, string> = (queryString: string) => {
    return queryString.split(',').reduce<Record<string, string>>((acc, pair) => {
        const lastIndex = pair.lastIndexOf('_');
        const key = pair.substring(0, lastIndex);
        const value = pair.substring(lastIndex + 1);
        acc[key] = value;
        return acc;
    }, {});
};

const formatCloudinaryParamsString: (queryParams: Record<string, string>) => string = (queryParams) => {
    return Object.entries(queryParams).map(([key, value]) => `${key}_${value}`).join(',');
};

function splitBy2(splited: string, splitBy: string): [string, string?] {
    const splitIndex = splited.indexOf(splitBy);
    if (splitIndex === -1) {
        return [splited];
    }
    const firstPart = splited.substring(0, splitIndex);
    const secondPart = splited.substring(splitIndex + splitBy.length);

    return [firstPart, secondPart];
}


function mergeCloudinaryParamsStrings(query1: string, query2: string) {

    const queryStringToObject = parseCloudinaryParamsString(query1);
    const queryStringToObject2 = parseCloudinaryParamsString(query2);

    // Merge the two objects, giving precedence to queryStringToObject2
    const mergedObject = { ...queryStringToObject, ...queryStringToObject2 };

    // Convert the merged object back into a query string
    return formatCloudinaryParamsString(mergedObject);
}


export {
    getId,
    getName,
    splitBy2,
    fetchProp,
    stringify,
    countChars,
    formatTime,
    getUsername,
    downloadURI,
    getExtension,
    truncateText,
    getSubstrings,
    replaceVariables,
    parseCloudinaryParamsString,
    mergeCloudinaryParamsStrings,
    formatCloudinaryParamsString,
    getLastFiveDigitsFromObjectId,
}