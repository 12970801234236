const orderDetailsEnum: any = {
    "Song Details": [
        {
            _id: 1,
            name: "Recipient",
            value: "[orderRecipient.name]"
        },
        {
            _id: 2,
            name: "Relationship to you",
            value: "[orderRecipientRelationship.recipientRelationship.name][orderRecipientRelationship.recipientRelationship.hints]"
        },
        {
            _id: 3,
            name: "Name mentioned?",
            value: "[mentionedName]"
        },
        {
            _id: 4,
            name: "Occasion",
            value: "[orderOccasion.occasion.name]"
        },
        {
            _id: 5,
            name: "Occasion mentioned?",
            value: "[mentionInSong]"
        },
    ],
    "Artist": [
        {
            _id: 6,
            name: "Genre",
            value: '[orderMusicTraits.where(musicTrait>traitType*0*musicTrait>name)]'
        },
        {
            _id: 7,
            name: "Beat",
            value: '[orderMusicTraits.where(musicTrait>traitType*1*musicTrait>name)]'
        },
        {
            _id: 8,
            name: "Feel",
            value: '[orderMusicTraits.where(musicTrait>traitType*2*musicTrait>name)]'
        },
    ],
    "Story": [
        {
            name: "Tell your artist about the recipient",
            value: '[aboutSong]',
            valueSx: {
                fontSize: '15px',
                color: "#A9B1B9",
            },
        }
    ],
    "Additional Verses": [
        {
            name: "Details",
            value: '[additionalVerses.0.details]',
            valueSx: {
                fontSize: '15px',
                color: "#A9B1B9",
            },
        }
    ],
}
enum ButtonType {
    Primary,
    Secondary,
    Tertiary,
}
enum orderStatusEnum {
    Draft, //* 0
    Pending, //* 1
    InProduction, //* 2
    Complete, //* 3
    Approved, //* 4
    OnHold, //* 5
    Cancelled, //* 6
    InReview, //* 7
    Revision, //* 8
}
enum PaymentStatus {
    Paid = "Paid",
    UnPaid = "NotPaidYet"
}
const enums: any = {
    traitTypes: [
        "Genre",
        "Beat",
        "Feel",
    ],
    gender: [
        "Male",
        "Female",
    ],
    orderStatuses: [
        "Draft",
        "Pending",
        "InProduction",
        "Complete",
        "Approved",
        "OnHold",
        "Cancelled",
        "InReview",
        "Revision",
    ],
    addonTypeName: [
        "Playback",
        "AdditionalVerse",
        "ArtistTip",
        "AudioGreeting",
        "LyricsSheet",
    ],
    clientChoiceStatus: [
        "Video",
        "Song",
    ]
}

export {
    ButtonType,
    orderStatusEnum,
    PaymentStatus,
    orderDetailsEnum,
};

export default enums
